<template>
  <v-navigation-drawer
    v-model="drawer"
    permanent
    elevation-0
    enable-resize-watcher
    :mini-variant.sync="show"
    app
    mobile-breakpoint="960"
    :style="cssProps"
  >
    <v-list-item class="px-2 py-4" dense>
      <v-btn icon height="50" class="px-4" @click.stop="show = !show">
        <v-icon size="16">fa fa-bars</v-icon>
      </v-btn>
      <v-list-item-content class="pr-16 pl-2 py-0">
        <v-img :src="getLogoUrl" height="50" contain></v-img>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <MenuOptions />
    <template v-slot:append>
      <SettingsOptions />
    </template>
  </v-navigation-drawer>
</template>

<script>
import logoUrl from "@/assets/logo.png";
import logoUrlDark from "@/assets/logo-dark.png";
import MenuOptions from "@/components/inc/MenuOptions";
import SettingsOptions from "@/components/inc/SettingsOptions";
export default {
  name: "DrawerApp",
  components: { MenuOptions, SettingsOptions },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      show: localStorage.getItem("drawer") == "true",
      group: null,
    };
  },
  computed: {
    cssProps() {
      var colorPrimary = this.$vuetify.theme.dark
        ? this.$vuetify.theme.themes["dark"].primary
        : this.$vuetify.theme.themes["light"].primary;
      return {
        "--primary-color": colorPrimary,
      };
    },
    getLogoUrl() {
      return this.$vuetify.theme.dark ? logoUrlDark : logoUrl;
    },
  },
  watch: {
    show(value) {
      localStorage.setItem("drawer", value);
    },
  },
};
</script>
<style scoped>
.v-list-item--link:before {
  background-color: var(--primary-color) !important;
  border-radius: 16px 0 0 16px;
}
.v-list .v-list-item--active {
  border-right: 4px solid var(--primary-color);
}
.v-list .v-list-item--active .v-list-item__title {
  font-weight: bold;
}
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: var(--primary-color) !important;
}
</style>
