<template>
  <div id="campaigns">
    <div v-if="getCampaignsBudgets && getProjects">
      <v-row class="d-flex align-center pt-4">
        <v-col cols="12" sm="6" md="9">
          <p class="text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down">Campañas</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="py-2 px-4 elevation-3 rounded-lg">
            <v-select
              v-model="project"
              value="todos"
              :items="getProjectsToSelect"
              label="Proyecto"
              item-text="id"
              item-value="id"
              class="px-1"
            ></v-select>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-card class="px-4 elevation-3 rounded-lg py-2">
            <v-text-field
              v-model="search"
              label="Buscar"
              dense
              solo
              single-line
              hide-details
              flat
              class="d-flex align-center imput-icon"
              append-icon="fa fa-search"
            ></v-text-field>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-4 hidden-md-and-up">
          <v-btn to="/campaigns/register-budget" color="primary" elevation-12 block>
            <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-5 pb-5 rounded-lg">
            <v-data-table
              :headers="headers"
              :items="getCampaignsBudgetsTableData"
              :items-per-page="50"
              :mobile-breakpoint="0"
              :search="search"
              dense
              class="text-headers-colors"
            >
              <template v-slot:item.campaign_date="{ item }">
                {{ $formatTimestamp(item.campaign_date, "only_date") }}
              </template>
              <template v-slot:item.operations="{ item }">
                <v-btn text icon @click="editCampaignBudget(item.id)">
                  <v-icon color="primary" dark small>far fa-edit</v-icon>
                </v-btn>
                <v-btn text icon @click="deleteCampaignBudget(item.id)">
                  <v-icon dark small>far fa-trash-alt</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-btn
        to="/campaigns/register-budget"
        fixed
        bottom
        right
        x-large
        color="primary"
        elevation-12
        rounded
        class="hidden-sm-and-down"
      >
        <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
      </v-btn>

      <!-- modals -->
      <v-dialog v-model="dialogDeleteCampaignBudget" persistent max-width="400">
        <v-card class="py-3">
          <v-card-text>
            <v-form ref="formDeleteCampaignBudget">
              <v-row>
                <v-col cols="12">
                  <h3 class="font-weight-thin">
                    ¿Está seguro que
                    <span class="font-weight-bold">
                      desea eliminar el registro del presupuesto de la campaña
                    </span>
                    ?.
                  </h3>
                </v-col>
                <v-col v-if="errorFormDeleteCampaignBudget" cols="12">
                  <v-alert dense text type="error">
                    {{ errorFormDeleteCampaignBudget }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="mr-2">
            <v-spacer></v-spacer>
            <v-btn rounded text @click="dialogDeleteCampaignBudget = false">Cancelar</v-btn>
            <v-btn :loading="loading" color="red" rounded dark @click="onDeleteCampaignBudget">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <CampaignBudgetDrawer
        v-if="campaignBudgetSelected"
        :campaign-budget-selected.sync="campaignBudgetSelected"
        :drawer.sync="campaignBudgetDrawer"
      />
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CampaignBudgetDrawer from "@/views/campaigns/CampaignBudgetDrawer.vue";

export default {
  name: "Campaigns",
  components: {
    CampaignBudgetDrawer,
  },
  data: () => ({
    headers: [
      { text: "Campaña", value: "campaign_name", align: "center", sortable: false },
      { text: "Proyecto", value: "project", align: "center", sortable: false },
      { text: "Canal", value: "channel", align: "center", sortable: false },
      { text: "Fecha", value: "campaign_date", align: "center" },
      { text: "Presupuesto ($)", align: "center", value: "amount" },
      { text: "Operación", align: "center", sortable: false, value: "operations" },
    ],
    search: "",
    project: "todos",
    campaignBudgetSelected: null,
    dialogDeleteCampaignBudget: false,
    errorFormDeleteCampaignBudget: null,
    loading: false,
    campaignBudgetDrawer: false,
  }),
  computed: {
    ...mapGetters(["getCampaignsBudgets", "getProjects"]),
    getProjectsToSelect() {
      return [{ id: "todos" }, ...this.getProjects];
    },
    getCampaignsBudgetsTableData() {
      let data = this.getCampaignsBudgets;
      if (this.project !== "todos") {
        data = data.filter((d) => d.project === this.project);
      }
      return data;
    },
  },
  async created() {
    await this.getAllAgents();
    await this.getAllProjects();
    await this.getAllCampaignsBudgets();
  },
  methods: {
    ...mapActions(["getAllAgents", "getAllProjects", "getAllCampaignsBudgets", "removeCampaignBudget"]),
    editCampaignBudget(id) {
      this.campaignBudgetSelected = this.getCampaignsBudgets.find((c) => c.id === id);
      this.campaignBudgetDrawer = true;
    },
    deleteCampaignBudget(id) {
      this.campaignBudgetSelected = this.getCampaignsBudgets.find((c) => c.id === id);
      this.dialogDeleteCampaignBudget = true;
    },
    async onDeleteCampaignBudget() {
      try {
        this.loading = true;
        this.errorFormDeleteCampaignBudget = null;

        if (this.$refs.formDeleteCampaignBudget.validate()) {
          await this.removeCampaignBudget({ id: this.campaignBudgetSelected.id });
        }

        this.campaignBudgetSelected = null;
        this.dialogDeleteCampaignBudget = false;
      } catch (error) {
        this.errorFormDeleteCampaignBudget =
          error.message || "Ocurrió un error al eliminar el presupuesto de la campaña.";
      }
    },
  },
};
</script>
