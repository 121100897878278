var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Card", {
        attrs: { id: "sendgrid", name: "SendGrid" },
        on: { open: _vm.open }
      }),
      _vm._v(" "),
      _vm.dialog
        ? _c("Modal", {
            attrs: { dialog: _vm.dialog },
            on: {
              "update:dialog": function($event) {
                _vm.dialog = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }