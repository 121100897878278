import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { integrationsCollection } from "@/config/firebase";

export default {
  async getAllIntegrations({ commit }) {
    let querySnapshot = await integrationsCollection.get();
    querySnapshot.forEach((doc) => {
      commit(MutationsType[`SET_INTEGRATION_${doc.id.toUpperCase()}`], { id: doc.id, ...doc.data() });
    });
  },
  async getIntegrationById({ commit }, payload) {
    let doc = await integrationsCollection.doc(payload.id).get();
    let config = doc.exists ? { id: doc.id, ...doc.data() } : null;

    commit(MutationsType.SET_INTEGRATION_CONFIG, config);
  },
  // eslint-disable-next-line
  async saveIntegration({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration",
        method: "POST",
        data: payload,
      });
      commit(MutationsType[`SET_INTEGRATION_${payload.id.toUpperCase()}`], response.data.data);
      return response.data;
    } catch (error) {
      console.error("saveIntegration: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async updateIntegration({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/integration/${payload.id}`,
        method: "PUT",
        data: payload.data,
      });
      commit(MutationsType[`SET_INTEGRATION_${payload.id.toUpperCase()}`], response.data.data);
      return response.data;
    } catch (error) {
      console.error("updateIntegration: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async removeIntegration({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/integration/${payload.id}`,
        method: "DELETE",
      });
      commit(MutationsType[`SET_INTEGRATION_${payload.id.toUpperCase()}`], null);
      return response.data;
    } catch (error) {
      console.error("removeIntegration: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async verifyIntegrationZoomWebinars({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/zoomwebinars/verify-account",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("verifyIntegrationZoomWebinars: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async registerUsersToZoomWebinar({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/zoomwebinars/register-attendees",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("registerUsersToZoomWebinar: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async verifyIntegrationSendGrid({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/sendgrid/verify-account",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("verifyIntegrationSendGrid: ", error);
      throw error.response.data;
    }
  },
};
