<template>
  <div>
    <v-form ref="formActivity" lazy-validation>
      <v-radio-group v-model="comment.type" row style="height: 40px;" class="justify-cennter items-center align-center">
        <v-radio
          :color="colorTypeComment[0]"
          :value="0"
          selected
          on-icon="fas fa-comment"
          off-icon="far fa-comment"
          class="px-4"
        />
        <v-radio
          :color="colorTypeComment[1]"
          :value="1"
          on-icon="fas fa-headset"
          off-icon="fas fa-headset"
          class="px-4"
        ></v-radio>
        <v-radio
          :color="colorTypeComment[2]"
          :value="2"
          on-icon="fas fa-envelope"
          off-icon="far fa-envelope"
          class="px-4"
        ></v-radio>
        <v-radio
          :color="colorTypeComment[4]"
          :value="4"
          on-icon="far fa-calendar-alt"
          off-icon="fas fa-calendar-alt"
          class="px-4"
        ></v-radio>
      </v-radio-group>
      <v-divider dark></v-divider>
      <v-col>
        <v-textarea
          v-model="comment.text"
          :rules="rules.required"
          flat
          label="Escribe un comentario..."
          auto-grow
          rows="1"
          class="expand"
        >
        </v-textarea>
      </v-col>
      <v-col v-if="comment.type === 4">
        <v-text-field
          v-model="comment.event_name"
          label="Nombre del evento"
          placeholder=" "
          :rules="rules.required"
          class="mb-4"
        />
        <v-row class="px-3">
          <v-col cols="12" :md="colFixed || 3" :lg="colFixed || 4" class="pa-0">
            <v-row>
              <v-col cols="3" class="d-flex">
                <p class="my-auto body-2">Fecha:</p>
              </v-col>
              <v-col cols="9">
                <v-menu
                  v-model="menuDateStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="300px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="comment.date_start"
                      class="mb-4"
                      :placeholder="today"
                      readonly
                      :rules="rules.required"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="comment.date_start" @input="menuDateStart = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" :md="colFixed || 8" :lg="colFixed || 6" class="pa-0">
            <v-row>
              <v-col cols="3" class="d-flex">
                <p class="my-auto body-2">Hora:</p>
              </v-col>
              <v-col cols="4" class="pr-0">
                <v-dialog
                  ref="dialogTimeStart"
                  v-model="dialogTimeStart"
                  :return-value.sync="comment.time_start"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="comment.time_start"
                      label=""
                      placeholder="00:00"
                      class="mb-4"
                      readonly
                      :rules="rules.required"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="dialogTimeStart" v-model="comment.time_start" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialogTimeStart = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.dialogTimeStart.save(comment.time_start)">Aceptar</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="1" class="d-flex">
                <p class="my-auto body-2">-</p>
              </v-col>
              <v-col cols="4">
                <v-dialog
                  ref="dialogTimeEnd"
                  v-model="dialogTimeEnd"
                  :return-value.sync="comment.time_end"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="comment.time_end"
                      label=""
                      placeholder="01:00"
                      class="mb-4"
                      readonly
                      :rules="[...rules.required, (v) => v > comment.time_start || 'Hora inválida']"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="dialogTimeEnd" v-model="comment.time_end" :min="comment.time_start" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialogTimeEnd = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.dialogTimeEnd.save(comment.time_end)">Aceptar</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-btn
        :color="colorTypeComment[comment.type]"
        block
        outlined
        class="mt-2"
        depressed
        @click.stop="commentTimeline"
      >
        <v-icon left dark>{{ iconTypeComment[comment.type] }}</v-icon>
        Comentar
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    ticketId: { type: String, default: "" },
    colFixed: { type: Number, default: 0 },
  },
  data() {
    return {
      today: this.$moment().format("YYYY-MM-DD"),
      comment: { type: 0 },
      rules: { required: [(v) => !!v || "Campo requerido"] },
      colorTypeComment: ["pink", "primary", "cyan", "teal", "green"],
      iconTypeComment: ["fas fa-comment", "fas fa-headset", "fas fa-envelope", "fas fa-user", "far fa-calendar-alt"],
      menuDateStart: false,
      dialogTimeStart: false,
      dialogTimeEnd: false,
    };
  },
  watch: {
    "comment.time_start"(value) {
      if (value && (!this.comment.time_end || this.comment.time_end < value)) {
        let date = this.$moment(`${this.comment.date_start ?? this.today} ${value}`).add(1, "h");
        this.comment.time_end = date.format("hh:mm");
      }
    },
  },
  methods: {
    ...mapActions(["addActivity"]),
    async commentTimeline() {
      if (this.$refs.formActivity.validate()) {
        let data = {
          ticket_id: this.ticketId,
          comment: this.comment,
        };
        this.addActivity(data);
        this.resetComment();
      }
    },
    resetComment() {
      this.comment = { type: 0 };
      this.$refs.formActivity.resetValidation();
    },
  },
};
</script>
