var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        {
          key: "webhooks",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.toggle = !_vm.toggle
            }
          }
        },
        [
          _c(
            "v-list-item-avatar",
            [_c("v-icon", [_vm._v("fa-network-wired")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            { staticClass: "py-4" },
            [
              _c("v-list-item-title", [_vm._v("Integraciones")]),
              _vm._v(" "),
              _c("v-list-item-subtitle", [
                _vm._v("Conecta tus canales digitales con el CRM")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-action",
            [
              _c("v-icon", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.toggle ? "fas fa-angle-down" : "fas fa-angle-up"
                    ) +
                    "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.getWebhooks && _vm.toggle
        ? _c(
            "v-container",
            { attrs: { id: "webhooks" } },
            [
              _vm.channels_enabled.length > 0
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("h5", { staticClass: "mb-4" }, [
                        _vm._v("Canales disponibles")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        _vm._l(_vm.channels_enabled, function(channel, i) {
                          return _c(
                            "v-col",
                            { key: i, attrs: { cols: "6", sm: "4", md: "2" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "py-3",
                                  on: {
                                    click: function($event) {
                                      return _vm.openConnect(channel.id)
                                    }
                                  }
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      src: require("@/assets/social_icons/" +
                                        channel.icon),
                                      width: "40"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "d-flex mt-2" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-center mx-auto caption"
                                      },
                                      [_vm._v(_vm._s(channel.name))]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("h5", { staticClass: "mb-2" }, [
                    _vm._v("Canales conectados")
                  ]),
                  _vm._v(" "),
                  _vm.getWebhooks.length === 0
                    ? _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("h4", { staticClass: "font-weight-thin" }, [
                            _vm._v(
                              "\n          No tiene canales conectados, vincule uno para generar más oportunidades a su negocio.\n        "
                            )
                          ])
                        ]
                      )
                    : _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _vm._l(_vm.getWebhooks, function(channel, i) {
                                return [
                                  _c(
                                    "v-col",
                                    {
                                      key: i,
                                      staticClass: "py-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "rounded-lg pa-2" },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "px-3" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "d-flex pa-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "6",
                                                    md: "6"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "my-auto px-2"
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        staticClass: "mx-auto",
                                                        attrs: {
                                                          src: require("@/assets/social_icons/" +
                                                            _vm
                                                              .getWebhooksTypes[
                                                              channel.id
                                                            ].icon),
                                                          width: "35"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "font-weight-thin text--overflow my-auto"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getWebhooksTypes[
                                                            channel.id
                                                          ].name
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "text-right d-flex",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "6",
                                                    md: "6"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "font-weight-thin grey--text text--darken-2 my-auto"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            "x-small": "",
                                                            color: channel.active
                                                              ? "success"
                                                              : "warning"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-circle"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          channel.active
                                                            ? "Activado"
                                                            : "Desactivado"
                                                        ) +
                                                          "\n                    "
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _vm.show_channel != channel.id
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            small: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.show_channel =
                                                                channel.id
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Ver detalle")]
                                                      )
                                                    : _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            small: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.show_channel = null
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Ocultar")]
                                                      ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        left: ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "my-auto",
                                                                        attrs: {
                                                                          "x-small":
                                                                            "",
                                                                          icon:
                                                                            ""
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          "x-small":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fas fa-ellipsis-v"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openEnable(
                                                                    channel.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      channel.active
                                                                        ? "Desactivar"
                                                                        : "Activar"
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openDelete(
                                                                    channel.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    "Eliminar"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.show_channel === channel.id
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _vm.show_channel ===
                                                          "messenger"
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "h5",
                                                                    {
                                                                      staticClass:
                                                                        "pb-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Páginas"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    channel.pages,
                                                                    function(
                                                                      page,
                                                                      j
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            key: j,
                                                                            staticClass:
                                                                              "py-1",
                                                                            attrs: {
                                                                              cols:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h5",
                                                                              {
                                                                                staticClass:
                                                                                  "font-weight-thin"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "- " +
                                                                                    _vm._s(
                                                                                      page.name
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                ],
                1
              ),
              _vm._v(" "),
              _vm.getIntegrationSendGrid
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [
                              _c("h5", { staticClass: "mb-4" }, [
                                _vm._v("Plataformas conectadas")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.getIntegrationSendGrid
                            ? _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [_c("SendgridDetail")],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "mt-4", attrs: { cols: "12" } },
                [
                  _c("h5", { staticClass: "mb-4" }, [
                    _vm._v("Otras integraciones")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _vm._l(_vm.integrations_enabled, function(
                        integration,
                        i
                      ) {
                        return _c(
                          "v-col",
                          { key: i, attrs: { cols: "6", sm: "4", md: "2" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "py-3",
                                on: {
                                  click: function($event) {
                                    return _vm.openConnect(integration.id)
                                  }
                                }
                              },
                              [
                                _c("v-img", {
                                  staticClass: "mx-auto",
                                  attrs: { src: integration.icon, width: "40" }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "d-flex mt-2" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-center mx-auto caption"
                                    },
                                    [_vm._v(_vm._s(integration.name))]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      !_vm.getIntegrationSendGrid
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6", sm: "4", md: "2" } },
                            [_c("SendgridCard")],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.dialog_messenger
                ? _c("WebhookMessenger", {
                    attrs: { "dialog-messenger": _vm.dialog_messenger },
                    on: {
                      "update:dialogMessenger": function($event) {
                        _vm.dialog_messenger = $event
                      },
                      "update:dialog-messenger": function($event) {
                        _vm.dialog_messenger = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.dialog_zoom_webinars
                ? _c("ZoomWebinars", {
                    attrs: { "dialog-zoom-webinars": _vm.dialog_zoom_webinars },
                    on: {
                      "update:dialogZoomWebinars": function($event) {
                        _vm.dialog_zoom_webinars = $event
                      },
                      "update:dialog-zoom-webinars": function($event) {
                        _vm.dialog_zoom_webinars = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.channel_selected
                ? _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "400" },
                      model: {
                        value: _vm.dialog_enabled,
                        callback: function($$v) {
                          _vm.dialog_enabled = $$v
                        },
                        expression: "dialog_enabled"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "h3",
                                      { staticClass: "font-weight-thin" },
                                      [
                                        _vm._v(
                                          "\n                ¿Desea\n                "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.channel_selected.active
                                                  ? "desactivar"
                                                  : "activar"
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          " el\n                canal " +
                                            _vm._s(
                                              _vm.getWebhooksTypes[
                                                _vm.channel_selected.id
                                              ].name
                                            ) +
                                            "?\n              "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "mr-2" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { rounded: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog_enabled = false
                                    }
                                  }
                                },
                                [_vm._v("Cancelar")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.loading,
                                    color: "primary",
                                    rounded: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(_vm.channel_selected)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.channel_selected.active
                                        ? "Desactivar"
                                        : "Activar"
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.channel_selected
                ? _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "400" },
                      model: {
                        value: _vm.dialog_delete,
                        callback: function($$v) {
                          _vm.dialog_delete = $$v
                        },
                        expression: "dialog_delete"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "h3",
                                      { staticClass: "font-weight-thin" },
                                      [
                                        _vm._v(
                                          "\n                ¿Está seguro que\n                "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              "desea eliminar la conección con " +
                                                _vm._s(
                                                  _vm.getWebhooksTypes[
                                                    _vm.channel_selected.id
                                                  ].name
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "? Con esta acción ya no se recibirán oportunidades desde este canal.\n              "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "mr-2" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { rounded: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog_delete = false
                                    }
                                  }
                                },
                                [_vm._v("Cancelar")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.loading,
                                    color: "red",
                                    rounded: "",
                                    dark: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.remove(_vm.channel_selected)
                                    }
                                  }
                                },
                                [_vm._v("Eliminar")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }