var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", "max-width": "800" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _c(
              "div",
              [
                _c("v-img", {
                  staticClass: "mr-2",
                  attrs: {
                    src: require("@/assets/social_icons/" + _vm.id + ".png"),
                    width: "20px"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "ml-0" }, [_vm._v("SendGrid")])
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", { staticClass: "font-weight-thin" }, [
                      _vm._v(
                        "\n            Está integración hará que se envíe correos tanto al asignado de la oportunidad, como a los supervisores\n            del equipo al que pertenezca.\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pt-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "caption",
                          attrs: { dense: "", text: "", type: "info" }
                        },
                        [
                          _vm._v(
                            "\n            Antes de empezar, le dejamos una guía para configuraciones previas a la\n            "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://docs.google.com/document/d/1C1yTnbAgr8Mf9jD4B-TLGcLjViT1zt_9TxfnxfKBktg/edit?usp=sharing",
                                target: "_blank"
                              }
                            },
                            [_vm._v("integración de SendGrid en Apolo CRM")]
                          ),
                          _vm._v(".\n          ")
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        { staticClass: "circle primary white--text mr-2" },
                        [_vm._v("1")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pr-5" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c("h4", { staticClass: "font-weight-thin" }, [
                                _vm._v("\n                Deberá ingresar el "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("correo electrónico")]
                                ),
                                _vm._v(" y\n                "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("token de accesso")]
                                ),
                                _vm._v(
                                  " de la cuenta SendGrid que enviará los correo.\n              "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  attrs: { "lazy-validation": "" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Correo electrónico",
                                              placeholder: " ",
                                              rules: _vm.rules.email
                                            },
                                            model: {
                                              value: _vm.email,
                                              callback: function($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Token de acceso",
                                              placeholder: " ",
                                              type: "password",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value: _vm.access_token,
                                              callback: function($$v) {
                                                _vm.access_token = $$v
                                              },
                                              expression: "access_token"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _vm.account_verification_error
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "error--text mr-4"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.account_verification_error
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.account_verified
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "green--text mr-4 font-weight-bold"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-check"
                                                  }),
                                                  _vm._v(" Verificado")
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                rounded: "",
                                                color: "accent",
                                                outlined: "",
                                                disabled: _vm.account_verified,
                                                loading: _vm.load_verify
                                              },
                                              on: { click: _vm.verify }
                                            },
                                            [_vm._v("Verificar")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        { staticClass: "circle primary white--text mr-2" },
                        [_vm._v("2")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pr-5" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c("h4", { staticClass: "font-weight-thin" }, [
                                _vm._v(
                                  "\n                Seleccione las opciones que desea para tener en cuenta en el envío automático de correos. Recuerde que\n                el envío al "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("agente asignado")]
                                ),
                                _vm._v(" es obligatorio.\n              ")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      value: true,
                                      readonly: "",
                                      color: "accent",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.send_agent_assigned,
                                      callback: function($$v) {
                                        _vm.send_agent_assigned = $$v
                                      },
                                      expression: "send_agent_assigned"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mt-2" }, [
                                    _vm._v("Envío al agente asignado")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      value: true,
                                      color: "accent",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.send_supervisors,
                                      callback: function($$v) {
                                        _vm.send_supervisors = $$v
                                      },
                                      expression: "send_supervisors"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      "Envío a supervisores del equipo del agente asignado"
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { rounded: "", text: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            rounded: "",
                            disabled: !_vm.account_verified,
                            loading: _vm.load_save
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v("\n            Guardar\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }