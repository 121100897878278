<template>
  <v-list dense>
    <v-list-item v-for="item in getMenuOptions" :key="item.title" link :to="item.path" class="my-1">
      <v-list-item-icon>
        <v-icon small>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import logoUrl from "@/assets/logo.png";
import logoUrlDark from "@/assets/logo-dark.png";
import { mapGetters } from "vuex";
export default {
  name: "MenuOptions",
  data() {
    return {
      show: false,
      group: null,
    };
  },
  computed: {
    ...mapGetters(["getMenuOptions"]),
    cssProps() {
      var colorPrimary = this.$vuetify.theme.dark
        ? this.$vuetify.theme.themes["dark"].primary
        : this.$vuetify.theme.themes["light"].primary;
      return {
        "--primary-color": colorPrimary,
      };
    },
    getLogoUrl() {
      return this.$vuetify.theme.dark ? logoUrlDark : logoUrl;
    },
  },
  watch: {
    drawer: {
      handler() {
        this.show = true;
      },
      deep: true,
    },
  },
  mounted() {
    this.verifyScreenSize();
  },

  methods: {
    // ...mapActions(["getAgentById", "updateNotificationToken"]),
    verifyScreenSize() {
      this.show = window.innerWidth >= 960;
    },
  },
};
</script>
<style scoped>
.v-list-item--link:before {
  background-color: var(--primary-color) !important;
  border-radius: 16px 0 0 16px;
}
.v-list .v-list-item--active {
  border-right: 4px solid var(--primary-color);
}
.v-list .v-list-item--active .v-list-item__title {
  font-weight: bold;
}
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: var(--primary-color) !important;
}
</style>
