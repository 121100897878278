<template>
  <div id="campaign_budget_form">
    <v-col v-if="campaignBudget && getProjects" cols="12" class="pa-0">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="campaignBudget.campaign_name"
              label="Nombre de campaña"
              placeholder=" "
              :rules="rules.required"
              :readonly="isReadOnly"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="sm || 6">
            <v-autocomplete
              v-model="campaignBudget.project"
              placeholder="Seleccione"
              :items="getProjects"
              item-value="id"
              item-text="id"
              :rules="rules.required"
              :readonly="isReadOnly"
              label="Proyecto"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" :sm="sm || 6">
            <v-menu v-model="menuDate" :close-on-content-click="false" :nudge-right="40" offset-y min-width="auto">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="campaignBudget.campaign_date"
                  placeholder="YYYY-MM-DD"
                  label="Fecha de inicio de campaña"
                  readonly
                  :rules="rules.required"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="campaignBudget.campaign_date" @input="menuDate = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" :sm="sm || 6">
            <v-autocomplete
              v-model="campaignBudget.channel"
              placeholder="Seleccione"
              :items="channels"
              :rules="rules.required"
              :readonly="isReadOnly"
              label="Canal"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" :sm="sm || 6">
            <v-text-field
              v-model="campaignBudget.amount"
              label="Presupuesto ($)"
              placeholder=" "
              :rules="rules.currency"
              :readonly="isReadOnly"
            ></v-text-field>
          </v-col>
          <v-col v-if="errorForm" cols="12">
            <v-alert text type="error">
              {{ errorForm }}
            </v-alert>
          </v-col>
          <v-col v-if="action === 'create'" cols="12">
            <v-btn color="primary" :loading="loading" @click="handleForm">Guardar presupuesto</v-btn>
          </v-col>
          <v-col v-if="action === 'update'" cols="12" class="text-center">
            <v-btn small rounded color="accent" :loading="loading" @click="handleForm">Guardar</v-btn>
            <v-btn small rounded @click="$emit('update:action', 'read')">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CampaignBudgetForm",
  props: {
    campaignBudgetSelected: {
      type: Object,
      default: function () {
        return {};
      },
    },
    action: {
      type: String,
      default: "read",
    },
    sm: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      campaignBudget: null,
      channels: [],
      menuDate: false,
      loading: false,
      errorForm: null,
      onEdit: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        // eslint-disable-next-line
        currency: [(v) => /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(v) || "Monto no válido"],
      },
    };
  },
  computed: {
    ...mapGetters(["getAllAgentsArray", "getProjects"]),
    isReadOnly() {
      return this.action === "read";
    },
  },
  watch: {
    getAllAgentsArray(value) {
      if (value) this.setChannels();
    },
    campaignBudgetSelected(value) {
      this.setCampaignBudget(value);
    },
  },
  mounted() {
    this.setCampaignBudget(this.campaignBudgetSelected);
    this.setChannels();
  },
  methods: {
    ...mapActions(["createCampaignBudget", "updateCampaignBudget"]),
    async handleForm() {
      try {
        this.loading = true;
        this.errorForm = null;

        if (this.$refs.form.validate()) {
          if (this.action === "create") {
            await this.createCampaignBudget(this.campaignBudget);
            this.$router.replace("/campaigns");
          } else {
            await this.updateCampaignBudget(this.campaignBudget);
            this.$emit("update:action", "read");
          }
        }
      } catch (error) {
        this.errorForm = error.message || "Ocurrió un error al actualizar el presupuesto de campaña";
      } finally {
        this.loading = false;
      }
    },
    setCampaignBudget(value) {
      if (value)
        this.campaignBudget = {
          id: value.id,
          campaign_name: value.campaign_name,
          campaign_date: value.campaign_date ? this.$formatTimestamp(value.campaign_date, "only_date") : "",
          amount: value.amount,
          channel: value.channel,
          project: value.project,
        };
    },
    setChannels() {
      let channels = ["facebook", "instagram", "google ads", "twitter", "linkdin"];
      let allChannels = [...channels];

      if (this.getAllAgentsArray)
        for (let i = 0; i < this.getAllAgentsArray.length; i++) {
          allChannels.push(...channels.map((c) => `${c} (${this.getAllAgentsArray[i].email})`));
        }

      this.channels = allChannels;
    },
  },
};
</script>
