var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "pt-8 pt-md-0 pt-lg-0", attrs: { id: "sale_detail" } },
    [
      _vm.getSale && _vm.getAgents
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-2 font-weight-bold pa-4",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n              INFORMACIÓN\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-4" },
                                    _vm._l(_vm.schemaSale, function(schema, i) {
                                      return _c(
                                        "v-col",
                                        { key: i, attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: schema.header,
                                              value:
                                                schema.type === "date"
                                                  ? _vm.$formatTimestamp(
                                                      _vm.getSale[schema.key],
                                                      "date_z"
                                                    )
                                                  : _vm.getSale[schema.key],
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-2 font-weight-bold pa-4",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n              DATOS DE LA ORDEN\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-4" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Fecha de registro",
                                              value: _vm.$formatTimestamp(
                                                _vm.getSale.created_at
                                              ),
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Vendedor",
                                              value: _vm.getSale.seller,
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.getSale.import_by
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    {
                                                      attrs: {
                                                        size: "50px",
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _vm.getAgents[
                                                        _vm.getSale.import_by
                                                      ].photo
                                                        ? _c("img", {
                                                            attrs: {
                                                              src:
                                                                _vm.getAgents[
                                                                  _vm.getSale
                                                                    .import_by
                                                                ].photo,
                                                              alt: "Avatar"
                                                            }
                                                          })
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "white--text headline"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.getAgents[
                                                                      _vm
                                                                        .getSale
                                                                        .import_by
                                                                    ].full_name.substring(
                                                                      0,
                                                                      1
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-2 w95",
                                                attrs: {
                                                  value:
                                                    _vm.getAgents[
                                                      _vm.getSale.import_by
                                                    ].full_name,
                                                  label: "Importado por:",
                                                  readonly: ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "7" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-2 font-weight-bold pa-4",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n              PRODUCTOS COMPRADOS\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-4" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-simple-table",
                                            {
                                              staticClass: "mb-4",
                                              staticStyle: {
                                                "overflow-x": "auto",
                                                border: "1px solid lightgrey"
                                              }
                                            },
                                            [
                                              _c(
                                                "thead",
                                                {
                                                  staticClass: "grey lighten-4"
                                                },
                                                [
                                                  _c("tr", [
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Nombre")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Cantidad")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Sutotal")]
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "tbody",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._l(
                                                    _vm.getSale.products,
                                                    function(product, i) {
                                                      return _c(
                                                        "tr",
                                                        { key: i },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(product.id)
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                product.quantity
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatCurrency(
                                                                  product.price *
                                                                    product.quantity
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right body-1",
                                                        attrs: { colspan: " 2" }
                                                      },
                                                      [_vm._v("Total")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "grey lighten-5"
                                                      },
                                                      [
                                                        _c(
                                                          "b",
                                                          {
                                                            staticClass:
                                                              "primary--text subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatCurrency(
                                                                  _vm.getSale
                                                                    .total_paid
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-2 font-weight-bold pa-4",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n              DETALLE DEL PAGO\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-4" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Método de pago:",
                                              value: _vm.getSale.payment_method,
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Estado del pedido",
                                              value: _vm.getSale.paid_status,
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.getSale.payment_detail &&
                                      _vm.getPaidStatus.find(function(e) {
                                        return (
                                          e.name === _vm.getSale.paid_status &&
                                          e.type === "error"
                                        )
                                      })
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    dense: "",
                                                    text: "",
                                                    type: "error"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    { staticClass: "mb-3" },
                                                    [
                                                      _vm._v(
                                                        "Detalle del error"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("pre", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getSale
                                                          .payment_detail
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }