export default {
  getIntegration(state) {
    return state.integration;
  },
  getIntegrationZoomWebinar(state) {
    return state.integrationZoomWebinar;
  },
  getIntegrationSendGrid(state) {
    return state.integrationSendGrid;
  },
};
