var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "formActivity", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-radio-group",
            {
              staticClass: "justify-cennter items-center align-center",
              staticStyle: { height: "40px" },
              attrs: { row: "" },
              model: {
                value: _vm.comment.type,
                callback: function($$v) {
                  _vm.$set(_vm.comment, "type", $$v)
                },
                expression: "comment.type"
              }
            },
            [
              _c("v-radio", {
                staticClass: "px-4",
                attrs: {
                  color: _vm.colorTypeComment[0],
                  value: 0,
                  selected: "",
                  "on-icon": "fas fa-comment",
                  "off-icon": "far fa-comment"
                }
              }),
              _vm._v(" "),
              _c("v-radio", {
                staticClass: "px-4",
                attrs: {
                  color: _vm.colorTypeComment[1],
                  value: 1,
                  "on-icon": "fas fa-headset",
                  "off-icon": "fas fa-headset"
                }
              }),
              _vm._v(" "),
              _c("v-radio", {
                staticClass: "px-4",
                attrs: {
                  color: _vm.colorTypeComment[2],
                  value: 2,
                  "on-icon": "fas fa-envelope",
                  "off-icon": "far fa-envelope"
                }
              }),
              _vm._v(" "),
              _c("v-radio", {
                staticClass: "px-4",
                attrs: {
                  color: _vm.colorTypeComment[4],
                  value: 4,
                  "on-icon": "far fa-calendar-alt",
                  "off-icon": "fas fa-calendar-alt"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { attrs: { dark: "" } }),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-textarea", {
                staticClass: "expand",
                attrs: {
                  rules: _vm.rules.required,
                  flat: "",
                  label: "Escribe un comentario...",
                  "auto-grow": "",
                  rows: "1"
                },
                model: {
                  value: _vm.comment.text,
                  callback: function($$v) {
                    _vm.$set(_vm.comment, "text", $$v)
                  },
                  expression: "comment.text"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.comment.type === 4
            ? _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass: "mb-4",
                    attrs: {
                      label: "Nombre del evento",
                      placeholder: " ",
                      rules: _vm.rules.required
                    },
                    model: {
                      value: _vm.comment.event_name,
                      callback: function($$v) {
                        _vm.$set(_vm.comment, "event_name", $$v)
                      },
                      expression: "comment.event_name"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "px-3" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0",
                          attrs: {
                            cols: "12",
                            md: _vm.colFixed || 3,
                            lg: _vm.colFixed || 4
                          }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-flex", attrs: { cols: "3" } },
                                [
                                  _c("p", { staticClass: "my-auto body-2" }, [
                                    _vm._v("Fecha:")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "300px"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        placeholder: _vm.today,
                                                        readonly: "",
                                                        rules:
                                                          _vm.rules.required
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.comment
                                                            .date_start,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.comment,
                                                            "date_start",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "comment.date_start"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2661996174
                                      ),
                                      model: {
                                        value: _vm.menuDateStart,
                                        callback: function($$v) {
                                          _vm.menuDateStart = $$v
                                        },
                                        expression: "menuDateStart"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-date-picker", {
                                        on: {
                                          input: function($event) {
                                            _vm.menuDateStart = false
                                          }
                                        },
                                        model: {
                                          value: _vm.comment.date_start,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.comment,
                                              "date_start",
                                              $$v
                                            )
                                          },
                                          expression: "comment.date_start"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0",
                          attrs: {
                            cols: "12",
                            md: _vm.colFixed || 8,
                            lg: _vm.colFixed || 6
                          }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-flex", attrs: { cols: "3" } },
                                [
                                  _c("p", { staticClass: "my-auto body-2" }, [
                                    _vm._v("Hora:")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "pr-0", attrs: { cols: "4" } },
                                [
                                  _c(
                                    "v-dialog",
                                    {
                                      ref: "dialogTimeStart",
                                      attrs: {
                                        "return-value": _vm.comment.time_start,
                                        persistent: "",
                                        width: "290px"
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          return _vm.$set(
                                            _vm.comment,
                                            "time_start",
                                            $event
                                          )
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.comment,
                                            "time_start",
                                            $event
                                          )
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "",
                                                        placeholder: "00:00",
                                                        readonly: "",
                                                        rules:
                                                          _vm.rules.required
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.comment
                                                            .time_start,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.comment,
                                                            "time_start",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "comment.time_start"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        600509762
                                      ),
                                      model: {
                                        value: _vm.dialogTimeStart,
                                        callback: function($$v) {
                                          _vm.dialogTimeStart = $$v
                                        },
                                        expression: "dialogTimeStart"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _vm.dialogTimeStart
                                        ? _c(
                                            "v-time-picker",
                                            {
                                              attrs: { "full-width": "" },
                                              model: {
                                                value: _vm.comment.time_start,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.comment,
                                                    "time_start",
                                                    $$v
                                                  )
                                                },
                                                expression: "comment.time_start"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.dialogTimeStart = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("Cancelar")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$refs.dialogTimeStart.save(
                                                        _vm.comment.time_start
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Aceptar")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "d-flex", attrs: { cols: "1" } },
                                [
                                  _c("p", { staticClass: "my-auto body-2" }, [
                                    _vm._v("-")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "v-dialog",
                                    {
                                      ref: "dialogTimeEnd",
                                      attrs: {
                                        "return-value": _vm.comment.time_end,
                                        persistent: "",
                                        width: "290px"
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          return _vm.$set(
                                            _vm.comment,
                                            "time_end",
                                            $event
                                          )
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.comment,
                                            "time_end",
                                            $event
                                          )
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "",
                                                        placeholder: "01:00",
                                                        readonly: "",
                                                        rules: _vm.rules.required.concat(
                                                          [
                                                            function(v) {
                                                              return (
                                                                v >
                                                                  _vm.comment
                                                                    .time_start ||
                                                                "Hora inválida"
                                                              )
                                                            }
                                                          ]
                                                        )
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.comment.time_end,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.comment,
                                                            "time_end",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "comment.time_end"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2911980231
                                      ),
                                      model: {
                                        value: _vm.dialogTimeEnd,
                                        callback: function($$v) {
                                          _vm.dialogTimeEnd = $$v
                                        },
                                        expression: "dialogTimeEnd"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _vm.dialogTimeEnd
                                        ? _c(
                                            "v-time-picker",
                                            {
                                              attrs: {
                                                min: _vm.comment.time_start,
                                                "full-width": ""
                                              },
                                              model: {
                                                value: _vm.comment.time_end,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.comment,
                                                    "time_end",
                                                    $$v
                                                  )
                                                },
                                                expression: "comment.time_end"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.dialogTimeEnd = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("Cancelar")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$refs.dialogTimeEnd.save(
                                                        _vm.comment.time_end
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Aceptar")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "mt-2",
              attrs: {
                color: _vm.colorTypeComment[_vm.comment.type],
                block: "",
                outlined: "",
                depressed: ""
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.commentTimeline($event)
                }
              }
            },
            [
              _c("v-icon", { attrs: { left: "", dark: "" } }, [
                _vm._v(_vm._s(_vm.iconTypeComment[_vm.comment.type]))
              ]),
              _vm._v("\n      Comentar\n    ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }