module.exports = {
  siteURL: "https://meridianbet.apolocrm.com",
  app: {
    apiKey: "AIzaSyCq3aicNV8JAAzzbqwIOnXRwWCtL45-9Kw",
    authDomain: "apolocrm-meridianbet.firebaseapp.com",
    databaseURL: "https://apolocrm-meridianbet.firebaseio.com",
    projectId: "apolocrm-meridianbet",
    storageBucket: "apolocrm-meridianbet.appspot.com",
    messagingSenderId: "374853364966",
    appId: "1:374853364966:web:d6d750ca4b88fd3360df27"
  },
  tokens: ["3ozSkxb4GX+w3EFkBAoJHT7MLn2WB1kL4FJLK0yx4IH3wqRce0MOuek35DSXqMY6dFrb+262rFzNr5KJekfxTA=="],
  gtm_id: "GTM-MBP8L4L",
  statitics_url: "https://datastudio.google.com/embed/reporting/04126857-13e4-4d04-aec5-ca1c41097793/page/DVWQB",
  report_activities_url: "https://datastudio.google.com/embed/reporting/4450a937-0145-438f-a47b-721ac18350ad/page/nYLqB",
  fcm_key: "BIUB8-iKpPZZoLSlveOW-CdPk6iih9UqlFQKwn6PWcO1am_PR9dYxbfDEycIrEJdQvoZ16VI06OT-7NLG-g2OGQ",
  algolia: { id: "AJMZBTLYEU", key: "fb1951ed55b6baf8282760af4188c0db", admin_key: "59280c4b623ffe456de0a4bfca3828d0" },
  contactStates: [
    { id: "nuevo", name: "Nuevo" },
    { id: "interesado", name: "Interesado" },
    { id: "sin-respuesta", name: "Sin respuesta" },
    {
      id: "desestimado",
      name: "Desestimado",
      reasons: [
        "Precio alto",
        "Falta de presupuesto",
        "Morosidad dificil manejar",
        "Junta de propietarios no desea, por invertir en otro gasto",
        "Invertir en su propio software",
        "Utilizan otro software",
        "Mejoras en el app cuando se compara con la  competencia.",
        "Soporte 24/07",
        "Recaudación multibanco",
        "Acceso a camaras de video vigilancia",
        "EXTRANJERO( Clientes bucan referencia en base cartera de clientes)",
        "Datos inválidos",
        "Otros",
      ]
    },
    { id: "curioso", name: "Curioso" },
    { id: "cliente", name: "Cliente" },
  ],
  contactRoles: ["Taxista", "Empresa Administradora", "Administrador Independiente"],
  contactFilters: {
    country: { id: "country", name: "País", primary: true },
    agent_id: { id: "agent_id", name: "Agente", primary: true, readonly_advisor: true, show_attr: "full_name" },
  },
  contactImportData: [
    { header: "Nombres", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Tipo de documento (DNI/Carnet de extranjería)", key: "document_type", width: 20, outlineLevel: 1, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, outlineLevel: 1, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Correo del Encargado", key: "agent_id", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  contactExportData: [
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Tipo de documento (DNI/Carnet de extranjería)", key: "document_type", width: 20, outlineLevel: 1, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, outlineLevel: 1, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "¿Aceptó términos y condiciones?", key: "terms", type: "terms", width: 20 },
    { header: "Última actividad", key: "last_comment", type: "string", width: 20 },
    { header: "Fecha de última actividad", key: "last_activity", type: "timestamp", width: 20 },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  contactFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) || "Teléfono no válido",
      ],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      no_edit: true,
    },
    document_type: {
      type: "select",
      label: "Tipo de documento",
      placeholder: "Seleccione",
      items: ["DNI", "Carnet de extranjería"],
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Departamento",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 } },
    newsletter: {
      type: "checkbox",
      label: "Desea recibir ofertas y promociones de Meridianbet",
      flex: { xs: 12 },
      class: "pt-5",
    },
    terms: {
      type: "checkbox",
      label: "Aceptó los términos y condiciones de Meridianbet",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  tableContact:  [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Email",
      align: "center",
      sortable: false,
      value: "email",
    },
    {
      text: "País",
      align: "center",
      sortable: false,
      value: "country",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  contactActivityImportData: [
    { header: "Correo electrónico", key: "contact_email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Tipo de actividad", key: "activity_type", width: 20, type: "activity" },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  saleImportData: [
    { header: "Nombres*", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Dirección", key: "address", width: 20, outlineLevel: 1, type: "string" },
    { header: "Producto", key: "products", width: 20, type: "product" },
    { header: "Cantidad", key: "quantity", width: 20, type: "product_detail" },
    { header: "Precio", key: "price", width: 20, type: "product_detail" },
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    { header: "Total de compra*", key: "total_paid", width: 20, type: "number" },
    { header: "Método de pago (PAGO EXITOSO/PROCESANDO PAGO)", key: "payment_method", width: 20, type: "string" },
    { header: "Estado del pago", key: "paid_status", width: 20, type: "string" },
    { header: "Vendedor", key: "seller", width: 20, type: "string" },
    { header: "Fecha de venta (dd/mm/yyyy)", key: "sales_date", width: 20, type: "date" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  saleExportData: [
    { header: "Id", key: "id", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30, show_on_detail: true },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30, show_on_detail: true },
    { header: "Correo electrónico", key: "email", type: "email", width: 30, show_on_detail: true },
    { header: "Teléfono", key: "phone", type: "string", width: 20, show_on_detail: true },
    { header: "Dirección", key: "address", type: "string", width: 20, show_on_detail: true },
    { header: "Zona", key: "zone", type: "string", width: 20, show_on_detail: true },
    { header: "Producto/Cantidad/Precio", key: "products", type: "products", width: 30},
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    { header: "Total de compra", key: "total_paid", type: "number", width: 20 },
    { header: "Estado de pago", key: "paid_status", type: "string", width: 20 },
    { header: "Método de pago", key: "payment_method", type: "string", width: 20 },
    { header: "Fecha de venta", key: "sales_date", width: 20, type: "date", show_on_detail: true },
    { header: "Vendedor", key: "seller", width: 20, type: "string" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Agente que importó la venta", key: "import_by", width: 20, type: "agent" }, 
  ],
  salePaidStatus: [
    { name: "PAGO EXITOSO", type: "success" },
    { name: "PROCESANDO PAGO", type: "procesing" },
    { name: "ERROR CULQI", type: "error" },
    { name: "ERROR VISANET", type: "error" },
  ],
  ticketStates: [
    { id: "nuevo", name: "Nuevo" },
    { id: "interesado", name: "Interesado" },
    { id: "sin-respuesta", name: "Sin respuesta" },
    { id: "desestimado", name: "Desestimado" },
    { id: "curioso", name: "Curioso" },
    { id: "marketing", name: "Marketing" },
    { id: "cliente", name: "Cliente" },
  ],
  ticketFilters: {
    state: { id: "state", name: "Estado", primary: true },
    origin: { id: "origin", name: "Origen", primary: true },
    agent_id: { id: "agent_id", name: "Agente", primary: true, readonly_advisor: true, show_attr: "full_name" },
  },
  tableTicket:  [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Origen",
      align: "center",
      sortable: false,
      value: "origin",
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "right",
      sortable: false,
      value: "operations",
    },
  ],
  ticketFormSchema: {
    ticket_name: {
      type: "text",
      label: "Nombre del ticket",
      placeholder: "Nombre del ticket",
      flex: { xs: 12 },
      show_on_detail: true,
      form_update: true,
    },
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) || "Teléfono no válido",
      ],
      show_on_detail: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      no_edit: true,
    },
    document_type: {
      type: "select",
      label: "Tipo de documento",
      placeholder: "Seleccione",
      items: ["DNI", "Carnet de extranjería"],
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Departamento",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    medium_contact: {
      type: "select",
      label: "Medio de contacto",
      items: ["Whatsapp", "Facebook", "Instagram", "Web", "Llamada", "Correo", "Sala de ventas", "Consulta", "Evento"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 } },
    tags: {
      type: "hidden",
      data_type: "tags",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
    terms: {
      type: "hidden",
      data_type: "boolean",
      label: "Terminos y condiciones",
      flex: { xs: 12 },
    },
  },
  ticketImportData: [
    { header: "Nombre del Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres*", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Tipo de documento (DNI/Carnet de extranjería)", key: "document_type", width: 20, outlineLevel: 1, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, outlineLevel: 1, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Correo del Encargado", key: "agent_id", width: 20, type: "agent" },
    { header: "Origen*", key: "origin", width: 20, type: "string" },
    { header: "Términos y condiciones (si/no)", key: "terms", width: 20, type: "boolean" },
  ],
  ticketExportData: [
    { header: "Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Tipo de documento", key: "document_type", width: 20, outlineLevel: 1, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, outlineLevel: 1, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Estado", key: "state", type: "state", width: 20 },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Última actividad", key: "last_comment", type: "string", width: 20 },
    { header: "Fecha de última actividad", key: "last_activity", type: "timestamp", width: 20 },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  ticketActivityImportData: [
    { header: "ID del Ticket", key: "ticket_id", width: 20, outlineLevel: 1, type: "string" },
    { header: "Tipo de actividad", key: "activity_type", width: 20, type: "activity" },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  agentTable:  [
    {
      text: "Encargado",
      align: "left",
      value: "full_name",
    },
    {
      text: "Correo electrónico",
      align: "center",
      value: "email",
    },
    {
      text: "Rol",
      align: "center",
      sortable: false,
      value: "role",
    },
    {
      text: "Equipo",
      align: "center",
      value: "team",
    },
    {
      text: "Proyectos",
      align: "center",
      value: "projects",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "active",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  agentFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#C43D2E",
        secondary: "#82b1ff",
        accent: "#82b1ff",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      },
      dark: {
        primary: "#C43D2E",
        secondary: "#82b1ff",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      }
    }
  }
 }; 