var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "campaign_budget_form" } },
    [
      _vm.campaignBudget && _vm.getProjects
        ? _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c(
                "v-form",
                { ref: "form", attrs: { "lazy-validation": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Nombre de campaña",
                              placeholder: " ",
                              rules: _vm.rules.required,
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.campaignBudget.campaign_name,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.campaignBudget,
                                  "campaign_name",
                                  $$v
                                )
                              },
                              expression: "campaignBudget.campaign_name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: _vm.sm || 6 } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              placeholder: "Seleccione",
                              items: _vm.getProjects,
                              "item-value": "id",
                              "item-text": "id",
                              rules: _vm.rules.required,
                              readonly: _vm.isReadOnly,
                              label: "Proyecto"
                            },
                            model: {
                              value: _vm.campaignBudget.project,
                              callback: function($$v) {
                                _vm.$set(_vm.campaignBudget, "project", $$v)
                              },
                              expression: "campaignBudget.project"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: _vm.sm || 6 } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                "offset-y": "",
                                "min-width": "auto"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              attrs: {
                                                placeholder: "YYYY-MM-DD",
                                                label:
                                                  "Fecha de inicio de campaña",
                                                readonly: "",
                                                rules: _vm.rules.required
                                              },
                                              model: {
                                                value:
                                                  _vm.campaignBudget
                                                    .campaign_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.campaignBudget,
                                                    "campaign_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "campaignBudget.campaign_date"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1514707349
                              ),
                              model: {
                                value: _vm.menuDate,
                                callback: function($$v) {
                                  _vm.menuDate = $$v
                                },
                                expression: "menuDate"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                on: {
                                  input: function($event) {
                                    _vm.menuDate = false
                                  }
                                },
                                model: {
                                  value: _vm.campaignBudget.campaign_date,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.campaignBudget,
                                      "campaign_date",
                                      $$v
                                    )
                                  },
                                  expression: "campaignBudget.campaign_date"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: _vm.sm || 6 } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              placeholder: "Seleccione",
                              items: _vm.channels,
                              rules: _vm.rules.required,
                              readonly: _vm.isReadOnly,
                              label: "Canal"
                            },
                            model: {
                              value: _vm.campaignBudget.channel,
                              callback: function($$v) {
                                _vm.$set(_vm.campaignBudget, "channel", $$v)
                              },
                              expression: "campaignBudget.channel"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: _vm.sm || 6 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Presupuesto ($)",
                              placeholder: " ",
                              rules: _vm.rules.currency,
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.campaignBudget.amount,
                              callback: function($$v) {
                                _vm.$set(_vm.campaignBudget, "amount", $$v)
                              },
                              expression: "campaignBudget.amount"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errorForm
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                { attrs: { text: "", type: "error" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.errorForm) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action === "create"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.handleForm }
                                },
                                [_vm._v("Guardar presupuesto")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action === "update"
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    rounded: "",
                                    color: "accent",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.handleForm }
                                },
                                [_vm._v("Guardar")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", rounded: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("update:action", "read")
                                    }
                                  }
                                },
                                [_vm._v("Cancelar")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }