export default {
  agent: null,
  agentSelected: null,
  agentAuthenticated: false,
  agents: null,
  allAgents: null,
  allAgentsMap: null,
  sellerAgents: [],
  agentRoles: {
    advisor: { id: "advisor", name: "Asesor" },
    supervisor: { id: "supervisor", name: "Supervisor" },
    admin: { id: "admin", name: "Administrador" },
    super_admin: { id: "super_admin", name: "Super Administrador" },
  },
  showAgentDetailDrawer: null,
  agentId: null,
  menu: [
    {
      icon: "fas fa-ticket-alt",
      title: "Oportunidades",
      path: "/opportunities",
      showOnBottomBar: true,
      items: [],
    },
    {
      icon: "fas fa-users",
      title: "Contactos",
      path: "/contacts",
      showOnBottomBar: true,
      items: [],
    },
    {
      icon: "fas fa-store",
      title: "Ventas",
      path: "/sales",
      items: [],
    },
    {
      icon: "far fa-calendar-alt",
      title: "Eventos",
      path: "/events",
      showOnBottomBar: true,
      items: [],
    },
    {
      icon: "fas fa-ad",
      title: "Campañas",
      path: "/campaigns",
      showOnBottomBar: true,
      items: [],
    },
  ],
};
