<template>
  <v-col cols="12" class="pa-0">
    <v-card class="rounded-lg pa-2">
      <v-col cols="12" class="py-0 px-3">
        <Detail
          :id="getIntegrationSendGrid.id"
          name="SendGrid"
          type="integration"
          :active="getIntegrationSendGrid.active"
          :show-detail.sync="showDetail"
          :error="error"
          :loading.sync="loading"
          @openEnable="openEnable"
          @openRemove="openRemove"
        />
      </v-col>
      <v-col v-if="showDetail" cols="12">
        <v-row>
          <v-col cols="12">
            <h5 class="pb-2">Envío de correos</h5>
          </v-col>
          <v-col cols="12" class="py-0 caption">
            <div class="d-flex">
              <v-checkbox
                v-model="getIntegrationSendGrid.send_agent_assigned"
                :value="true"
                readonly
                color="accent"
                hide-details
              ></v-checkbox>
              <span class="mt-2">Envío al agente asignado</span>
            </div>
            <div class="d-flex">
              <v-checkbox
                v-model="getIntegrationSendGrid.send_supervisors"
                :value="true"
                readonly
                color="accent"
                hide-details
              ></v-checkbox>
              <span class="mt-2">Envío a supervisores del equipo del agente asignado</span>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <ModalEnableIntegration
        v-if="dialogToEnable"
        name="SendGrid"
        :dialog-to-enable.sync="dialogToEnable"
        :active.sync="getIntegrationSendGrid.active"
        :error.sync="error"
        :loading.sync="loading"
        @enable="enable"
      />
      <ModalRemoveIntegration
        v-if="dialogToRemove"
        name="SendGrid"
        :dialog-to-remove.sync="dialogToRemove"
        :error.sync="error"
        :loading.sync="loading"
        @remove="remove"
      />
    </v-card>
  </v-col>
</template>
<script>
import Detail from "@/components/integrations/Detail";
import ModalEnableIntegration from "@/views/settings/modals/EnableIntegration";
import ModalRemoveIntegration from "@/views/settings/modals/RemoveIntegration";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SendGridDetail",
  components: {
    Detail,
    ModalEnableIntegration,
    ModalRemoveIntegration,
  },
  data: () => ({
    showDetail: false,
    dialogToEnable: false,
    dialogToRemove: false,
    loading: false,
    error: null,
  }),
  computed: {
    ...mapGetters(["getIntegrationSendGrid"]),
  },
  methods: {
    ...mapActions(["updateIntegration", "removeIntegration"]),
    openEnable() {
      this.dialogToEnable = true;
    },
    openRemove() {
      this.dialogToRemove = true;
    },
    async enable() {
      try {
        this.loading = true;
        this.error = null;

        await this.updateIntegration({
          id: "sendgrid",
          data: { active: !this.getIntegrationSendGrid.active },
        });

        this.dialogToEnable = false;
      } catch (error) {
        this.error = error.message || "Hubo un problema al activar/desactivar la integración";
      } finally {
        this.loading = false;
      }
    },
    async remove() {
      try {
        this.loading = true;
        this.error = null;

        await this.removeIntegration({ id: "sendgrid" });

        this.dialogToRemove = false;
      } catch (error) {
        this.error = error.message || "Hubo un problema al eliminar la integración con SendGrid";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
