<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800">
    <v-card class="py-3">
      <v-card-title class="headline">
        <div>
          <v-img :src="require(`@/assets/social_icons/${id}.png`)" width="20px" class="mr-2"></v-img>
        </div>
        <span class="ml-0">SendGrid</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h4 class="font-weight-thin">
              Está integración hará que se envíe correos tanto al asignado de la oportunidad, como a los supervisores
              del equipo al que pertenezca.
            </h4>
          </v-col>
          <v-col cols="12" class="pt-2">
            <v-alert dense text type="info" class="caption">
              Antes de empezar, le dejamos una guía para configuraciones previas a la
              <a
                href="https://docs.google.com/document/d/1C1yTnbAgr8Mf9jD4B-TLGcLjViT1zt_9TxfnxfKBktg/edit?usp=sharing"
                target="_blank"
                >integración de SendGrid en Apolo CRM</a
              >.
            </v-alert>
          </v-col>
          <v-col cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">1</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  Deberá ingresar el <span class="font-weight-bold">correo electrónico</span> y
                  <span class="font-weight-bold">token de accesso</span> de la cuenta SendGrid que enviará los correo.
                </h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-form ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="email"
                        label="Correo electrónico"
                        placeholder=" "
                        :rules="rules.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="access_token"
                        label="Token de acceso"
                        placeholder=" "
                        type="password"
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <span v-if="account_verification_error" class="error--text mr-4">{{
                        account_verification_error
                      }}</span>
                      <span v-if="account_verified" class="green--text mr-4 font-weight-bold"
                        ><i class="fas fa-check"></i> Verificado</span
                      >
                      <v-btn
                        rounded
                        color="accent"
                        outlined
                        :disabled="account_verified"
                        :loading="load_verify"
                        @click="verify"
                        >Verificar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">2</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  Seleccione las opciones que desea para tener en cuenta en el envío automático de correos. Recuerde que
                  el envío al <span class="font-weight-bold">agente asignado</span> es obligatorio.
                </h4>
              </v-col>
              <v-col cols="12" class="pt-0">
                <div class="d-flex">
                  <v-checkbox
                    v-model="send_agent_assigned"
                    :value="true"
                    readonly
                    color="accent"
                    hide-details
                  ></v-checkbox>
                  <span class="mt-2">Envío al agente asignado</span>
                </div>
                <div class="d-flex">
                  <v-checkbox v-model="send_supervisors" :value="true" color="accent" hide-details></v-checkbox>
                  <span class="mt-2">Envío a supervisores del equipo del agente asignado</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn rounded text @click="closeDialog">Cancelar</v-btn>
            <v-btn color="primary" rounded :disabled="!account_verified" :loading="load_save" @click="save">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SendGridModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: "sendgrid",
      email: "",
      access_token: "",
      email_verified: "",
      access_token_verified: "",
      send_agent_assigned: true,
      send_supervisors: true,
      account_verification_error: null,
      account_verified: false,
      error_message: null,
      load_verify: false,
      load_save: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getIntegration"]),
  },
  watch: {
    email(value) {
      if (this.email_verified && this.email_verified !== value) this.account_verified = false;
    },
    accessToken(value) {
      if (this.access_token_verified && this.access_token_verified !== value) this.account_verified = false;
    },
    getIntegration(value) {
      if (value) {
        this.email = value.email;
        this.access_token = value.access_token;
      }
    },
  },
  methods: {
    ...mapActions(["saveIntegration", "verifyIntegrationSendGrid"]),
    async verify() {
      try {
        this.load_verify = true;
        this.account_verification_error = null;

        if (this.$refs.form.validate()) {
          const response = await this.verifyIntegrationSendGrid({
            email: this.email,
            access_token: this.access_token,
          });

          this.account_verified = true;
          this.email_verified = response.data.email;
          this.access_token_verified = response.data.access_token;
        }
      } catch (error) {
        this.account_verification_error = error.message || "Hubo problemas al vincular la cuenta de SendGrid";
      } finally {
        this.load_verify = false;
      }
    },
    async save() {
      try {
        this.load_save = true;
        this.error_message = null;

        await this.saveIntegration({
          id: "sendgrid",
          data: {
            email: this.email_verified,
            access_token: this.access_token_verified,
            send_agent_assigned: this.send_agent_assigned,
            send_supervisors: this.send_supervisors,
          },
        });

        this.closeDialog();
      } catch (error) {
        this.error_message = error.message || "Hubo problemas al vincular la cuenta de SendGrid";
      } finally {
        this.load_save = false;
      }
    },
    closeDialog() {
      this.$emit("update:dialog", false);
    },
  },
};
</script>
