<template>
  <v-dialog v-model="dialogZoomWebinars" scrollable persistent max-width="800">
    <v-card class="py-3">
      <v-card-title class="headline">
        <span class="ml-0">Zoom Webinars</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h4 class="font-weight-thin">
              Está integración le permitirá registrar asistentes a uno de sus webinars creados.
            </h4>
          </v-col>
          <v-col cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">1</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  Deberá ingresar el <span class="font-weight-bold">Usuario ID o correo electrónico</span> y el
                  <span class="font-weight-bold">token de accesso</span> de la cuenta Zoom que cuenta con el complemento
                  de Webinars para verificar la conexión.
                </h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-form ref="formVerify" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="user"
                        label="User ID o correo electrónico"
                        placeholder=" "
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="access_token"
                        label="Token de acceso"
                        placeholder=" "
                        type="password"
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <span v-if="verify_error_msg" class="error--text mr-4">{{ verify_error_msg }}</span>
                      <span v-if="account_verified" class="green--text mr-4 font-weight-bold"
                        ><i class="fas fa-check"></i> Verificado</span
                      >
                      <v-btn
                        rounded
                        color="accent"
                        outlined
                        :disabled="account_verified"
                        :loading="load_verify"
                        @click="verify"
                        >Verificar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="getActiveTags" cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">2</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  <span class="font-weight-bold">Seleccione su audencia</span>, y luego
                  <span class="font-weight-bold">seleccione el webinar</span> al cual se va a importar a los asistentes.
                </h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-form ref="formFilters" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        v-model="menu_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date_start"
                            label="Fecha inicial"
                            prepend-icon="far fa-calendar-alt"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_start" :max="date_end" @input="menu_start = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        v-model="menu_end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date_end"
                            label="Fecha final"
                            prepend-icon="far fa-calendar-alt"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_end" @input="menu_end = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="origin"
                        :items="origins"
                        item-value="id"
                        item-text="name"
                        label="Origen"
                        placeholder="Todos"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-select
                        v-model="tags"
                        :items="getActiveTags"
                        item-value="id"
                        item-text="name"
                        attach
                        chips
                        label="Etiquetas"
                        placeholder=" "
                        :rules="rules.max10"
                        multiple
                        class="expand"
                      >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip v-bind="attrs" :input-value="selected" :color="item.color" @click="select">
                            <strong>{{ item.name }}</strong
                            >&nbsp;
                            <span></span>
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" class="text-right pt-0">
                      <v-btn rounded color="accent" outlined :loading="load_view" @click="view">Visualizar 5</v-btn>
                    </v-col>
                    <v-col v-if="tickets" cols="12" class="text-center">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">Nombres y Apellidos</th>
                              <th class="text-center">Correo electrónico</th>
                              <th class="text-center">Teléfono</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in tickets" :key="item.id">
                              <td class="text-center">{{ item.full_name }}</td>
                              <td class="text-center">{{ item.email }}</td>
                              <td class="text-center">{{ item.phone || "-" }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <span v-if="tickets.length === 0" class="text-center caption">No hay registros</span>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="webinar"
                  :items="webinars"
                  item-value="id"
                  item-text="topic"
                  label="Webinar"
                  placeholder="Seleccione"
                  :rules="rules.required"
                ></v-autocomplete>
              </v-col>
              <v-col v-if="message" cols="12">
                <v-alert dense text :type="status ? 'success' : 'error'">
                  {{ message }}
                </v-alert>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn rounded text @click="closeDialog">Cancelar</v-btn>
                <v-btn
                  v-if="!status"
                  rounded
                  color="primary"
                  class="my-auto ml-5"
                  :disabled="!account_verified || !webinar"
                  :loading="load_import"
                  @click="importAttendees"
                  >Importar</v-btn
                >
                <v-btn v-else color="primary" rounded @click="closeDialog">Listo</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ticketsIndex } from "@/config/algolia";

export default {
  name: "ZoomWebinars",
  props: {
    dialogZoomWebinars: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      account_verified: false,
      user: "",
      access_token: "",
      user_verified: "",
      access_token_verified: "",
      origin: "",
      origins: [],
      date_start: this.$moment().format("YYYY-MM-DD"),
      date_end: this.$moment().format("YYYY-MM-DD"),
      tags: [],
      webinar: "",
      webinars: [],
      verify_error_msg: null,
      menu_start: false,
      menu_end: false,
      status: null,
      message: null,
      tickets: null,
      load_verify: false,
      load_view: false,
      load_import: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        max10: [(v) => v.length < 11 || "Máximo 10 etiquetas"],
      },
    };
  },
  computed: {
    ...mapGetters(["getIntegration", "getActiveTags"]),
  },
  watch: {
    getIntegration(value) {
      if (value) {
        this.user = value.user;
        this.access_token = value.access_token;
        this.verify();
      }
    },
    user(value) {
      if (this.user_verified && this.user_verified !== value) this.account_verified = false;
    },
    access_token(value) {
      if (this.access_token_verified && this.access_token_verified !== value) this.account_verified = false;
    },
    date_end(value) {
      if (this.date_start && this.date_start > value) this.date_start = this.date_end;
    },
    account_verified(value) {
      if (value === false) {
        this.webinar = "";
        this.webinars = [];
      }
    },
  },
  async created() {
    await this.getIntegrationById({ id: "zoom_webinars" });
    await this.getAllTags();
    await this.getOrigins();
  },
  methods: {
    ...mapActions([
      "getIntegrationById",
      "getAllTags",
      "getTicketsWithFilters",
      "verifyIntegrationZoomWebinars",
      "registerUsersToZoomWebinar",
    ]),
    async verify() {
      try {
        this.load_verify = true;
        this.verify_error_msg = null;

        if (this.$refs.formVerify.validate()) {
          let response = await this.verifyIntegrationZoomWebinars({
            user: this.user,
            access_token: this.access_token,
          });

          if (response.error) throw response;

          this.user_verified = this.user;
          this.access_token_verified = this.access_token;
          this.account_verified = true;
          this.webinars = response.data.webinars;
        }
      } catch (error) {
        this.verify_error_msg = error.message || "Ocurrió un error al verificar";
        this.account_verified = false;
      } finally {
        this.load_verify = false;
      }
    },
    async view() {
      try {
        this.load_view = true;

        this.tickets = await this.getTicketsWithFilters({
          filters: {
            date_start: this.date_start,
            date_end: this.date_end,
            tags: this.tags,
            origin: this.origin,
          },
        });
      } catch (error) {
        console.error("view: ", error);
      } finally {
        this.load_view = false;
      }
    },
    async importAttendees() {
      try {
        this.load_import = true;

        if (this.$refs.formFilters.validate() && this.webinar) {
          let response = await this.registerUsersToZoomWebinar({
            user: this.user,
            access_token: this.access_token,
            webinar_id: this.webinar,
            filters: {
              date_start: this.date_start,
              date_end: this.date_end,
              tags: this.tags,
              origin: this.origin,
            },
          });
          this.message = response.message;
          this.status = true;
        }
      } catch (error) {
        this.message = error.message || "Ocurrió un error al importar asistentes";
        this.status = false;

        console.error("importAttendees: ", error);
      } finally {
        this.load_import = false;
      }
    },
    async getOrigins() {
      let origins = [];
      let data = await ticketsIndex.search("", {
        facets: "origin",
        hitsPerPage: 10,
      });

      if (data) for (let origin in data.facets["origin"]) origins.push({ id: origin, name: origin });

      this.origins.push(...origins);
    },
    closeDialog() {
      this.origin = "";
      this.tags = [];
      this.date_start = this.$moment().format("YYYY-MM-DD");
      this.date_end = this.$moment().format("YYYY-MM-DD");
      this.account_verified = null;
      this.webinar = "";
      this.webinars = [];
      this.tickets = null;
      this.$emit("update:dialogZoomWebinars", false);
    },
  },
};
</script>
