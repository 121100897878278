<template>
  <div>
    <Card id="sendgrid" name="SendGrid" @open="open" />

    <Modal v-if="dialog" :dialog.sync="dialog" />
  </div>
</template>
<script>
import Card from "@/components/integrations/Card";
import Modal from "./Modal";

export default {
  name: "SendGridCard",
  components: {
    Card,
    Modal,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>
