<template>
  <v-app id="sale_detail" class="pt-8 pt-md-0 pt-lg-0">
    <v-row v-if="getSale && getAgents">
      <v-col cols="12" md="5">
        <v-row>
          <v-col cols="12" class="mt-0">
            <v-card>
              <v-col class="grey--text text--darken-2 font-weight-bold pa-4" cols="12">
                INFORMACIÓN
              </v-col>
              <v-col cols="12">
                <v-row class="px-4">
                  <v-col v-for="(schema, i) in schemaSale" :key="i" cols="12">
                    <v-text-field
                      :label="schema.header"
                      :value="
                        schema.type === 'date' ? $formatTimestamp(getSale[schema.key], 'date_z') : getSale[schema.key]
                      "
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-0">
            <v-card>
              <v-col class="grey--text text--darken-2 font-weight-bold pa-4" cols="12">
                DATOS DE LA ORDEN
              </v-col>
              <v-col cols="12">
                <v-row class="px-4">
                  <v-col cols="12">
                    <v-text-field
                      label="Fecha de registro"
                      :value="$formatTimestamp(getSale.created_at)"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Vendedor" :value="getSale.seller" readonly></v-text-field>
                  </v-col>
                  <v-col v-if="getSale.import_by" class="d-flex" cols="12">
                    <div>
                      <v-avatar size="50px" color="black">
                        <img
                          v-if="getAgents[getSale.import_by].photo"
                          :src="getAgents[getSale.import_by].photo"
                          alt="Avatar"
                        />
                        <span v-else class="white--text headline">
                          {{ getAgents[getSale.import_by].full_name.substring(0, 1) }}
                        </span>
                      </v-avatar>
                    </div>
                    <v-text-field
                      :value="getAgents[getSale.import_by].full_name"
                      label="Importado por:"
                      class="pl-2 w95"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="7">
        <v-row>
          <v-col cols="12" class="mt-0">
            <v-card>
              <v-col class="grey--text text--darken-2 font-weight-bold pa-4" cols="12">
                PRODUCTOS COMPRADOS
              </v-col>
              <v-col cols="12">
                <v-row class="px-4">
                  <v-col cols="12">
                    <v-simple-table class="mb-4" style="overflow-x: auto; border: 1px solid lightgrey;">
                      <thead class="grey lighten-4">
                        <tr>
                          <th class="text-center">Nombre</th>
                          <th class="text-center">Cantidad</th>
                          <th class="text-center">Sutotal</th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr v-for="(product, i) in getSale.products" :key="i">
                          <td>{{ product.id }}</td>
                          <td>{{ product.quantity }}</td>
                          <td>{{ formatCurrency(product.price * product.quantity) }}</td>
                        </tr>
                        <tr>
                          <td colspan=" 2" class="text-right body-1">Total</td>
                          <td class="grey lighten-5">
                            <b class="primary--text subtitle">{{ formatCurrency(getSale.total_paid) }}</b>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" class="mt-0">
            <v-card>
              <v-col class="grey--text text--darken-2 font-weight-bold pa-4" cols="12">
                DETALLE DEL PAGO
              </v-col>
              <v-col cols="12">
                <v-row class="px-4">
                  <v-col cols="12" md="6">
                    <v-text-field label="Método de pago:" :value="getSale.payment_method" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Estado del pedido" :value="getSale.paid_status" readonly></v-text-field>
                  </v-col>
                  <v-col
                    v-if="
                      getSale.payment_detail &&
                      getPaidStatus.find((e) => e.name === getSale.paid_status && e.type === 'error')
                    "
                    cols="12"
                  >
                    <v-alert dense text type="error">
                      <h4 class="mb-3">Detalle del error</h4>
                      <pre>{{ getSale.payment_detail }}</pre>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { saleExportData } from "Config/config";

export default {
  name: "SaleDetail",
  data() {
    return {
      sale_id: this.$route.params.id,
      schemaSale: null,
    };
  },
  computed: {
    ...mapGetters(["getSale", "getPaidStatus", "getAgents"]),
  },
  async created() {
    this.schemaSale = saleExportData.filter((s) => s.show_on_detail);

    await this.getSaleById({ sale_id: this.sale_id });
    await this.getAllAgents();
  },
  methods: {
    ...mapActions(["getSaleById", "getAllAgents"]),
    formatCurrency(number, symbol = "S/.") {
      if (isNaN(number)) number = 0;
      return `${symbol} ${parseFloat(number)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
    },
  },
};
</script>
